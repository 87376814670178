import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Global electrical design engineering consultancy
        </p>
        <p>
          me@nickturner.au
        </p>
        <p>
          Currently on design of standalone power systems under 50MW
        </p>
        <p>
          <a href="https://www.engineersaustralia.org.au/sites/default/files/2022-08/code-ethics-guidelines-professional-conduct-2022.pdf">Engineers Australia</a>
        </p>
      </header>
    </div>
  );
}

export default App;
